import { VideoAssetResponse } from '$Generated/api';

export function buildUrl(asset: VideoAssetResponse | undefined, baseUrl: string) {
    let vidUrl = "";
    if (asset) {
        const storageDetails = JSON.parse(asset.storageDetailsJson || "");
        if (asset.videoAssetStorageId && asset.videoAssetStorageId === "Controller") {
            vidUrl = baseUrl + "/api/v1" + storageDetails.URI;
        } else if (asset.videoAssetStorageId && asset.videoAssetStorageId === "GcpFs") {
            vidUrl = storageDetails.signedURL;
        } 
        
    }
    return vidUrl;
}
