import { ConfigService, IConfigServiceInjectedProps } from "$State/ConfigFreezerService";
import { GetImageUrl } from "$Utilities/dataModelUtilities";
import { React} from "../Imports";
import {  Card, Typography, CardContent, Container } from "../MaterialUIComponents";
import { IEventReviewServiceInjectedProps, EventReviewService } from "../state/EventReviewFreezer";

interface IEventExpiredBaseProps {
}

type IEventExpiredProps = IEventExpiredBaseProps & IEventReviewServiceInjectedProps & IConfigServiceInjectedProps;

interface IEventExpiredState {
    errorMsg: string;
    id: string;
    token: string;
    passwordHasError: boolean;

}

const styles = require("./EventExpired.scss") as {
    main: string,
    loginbutton: string,
    content: string,
    centeredText: string;
    buttonContainer: string;
    flex: string;
    bottomInfo: string;
    topInfo: string;
    logo: string;
};

class _EventExpired extends React.Component<IEventExpiredProps, IEventExpiredState> {
    state: IEventExpiredState = {
        errorMsg: "",
        id: "",
        token: "",
        passwordHasError: false
    };

    componentDidMount() {
        this.props.eventReview.resetRedirectTo();
    }

    render() {
        let currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        let currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;
       
        return (
            <Container className={styles.main}>
                <Card className={styles.content} >
                <img className={styles.logo} src={GetImageUrl(currentImagesBucket? currentImagesBucket : '', currentRegion? currentRegion : '', 'videoProtectsStacked.png')} />
                    <CardContent className={styles.centeredText}>
                        <Typography variant="h5" component="h2" align="center">
                            The link is no longer valid.
                        </Typography>
                       
                       
                    </CardContent>
                    
                 
                </Card>
                
            </Container>
        );
    }
}

export const EventExpiredPage = EventReviewService.inject(ConfigService.inject(_EventExpired));