import { React, bind, moment } from "../Imports";
import { TextField, Button, Link, Card, Typography, CardContent, Container } from "../MaterialUIComponents";
import { IEventReviewServiceInjectedProps, EventReviewService } from "../state/EventReviewFreezer";

import * as MediaUrlBuilder from '../components/Shared/MediaUrlBuilder';
import { EventVideo } from '../components/EventVideo';
import { IConfigServiceInjectedProps, ConfigService } from "$State/ConfigFreezerService";
import { GetImageUrl } from "$Utilities/dataModelUtilities";
interface IDriverDiscussionSentBaseProps {
}

type IDriverDiscussionSentProps = IDriverDiscussionSentBaseProps & IEventReviewServiceInjectedProps & IConfigServiceInjectedProps;

interface IDriverDiscussionSentState {
    errorMsg: string;
    id: string;
    token: string;
    passwordHasError: boolean;

}

const styles = require("./DriverDiscussionSent.scss") as {
    main: string,
    loginbutton: string,
    content: string,
    centeredText: string;
    buttonContainer: string;
    flex: string;
    bottomInfo: string;
    topInfo: string;
    logo: string; 
};

class _DriverDiscussionSent extends React.Component<IDriverDiscussionSentProps, IDriverDiscussionSentState> {
    state: IDriverDiscussionSentState = {
        errorMsg: "",
        id: "",
        token: "",
        passwordHasError: false
    };

    componentDidMount() {
        this.props.eventReview.resetRedirectTo();
    }
    
    render() {
        let currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        let currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;
       
        return (
            <Container className={styles.main}>
                <Card className={styles.content} >
                <img className={styles.logo} src={GetImageUrl(currentImagesBucket? currentImagesBucket : '', currentRegion? currentRegion : '', 'videoProtectsStacked.png')} />
                    <CardContent className={styles.centeredText}>
                        <Typography variant="h5" component="h2" align="center" className={styles.centeredText}> 
                        Your feedback has been sent to the fleet manager. You will be contacted soon for a discussion about your event.
                        </Typography>
                    </CardContent>
                </Card>
                
            </Container>
        );
    }
}

export const DriverDiscussionSentPage = EventReviewService.inject(ConfigService.inject(_DriverDiscussionSent));