import { FreezerService, managedAjaxUtil, IAjaxState } from '../Imports';
import { ConfigApiFactory, ClientConfigResponse } from '$Generated/api';

const InjectedPropName = 'config';

export interface IConfigState {
    clientConfigResults: IAjaxState<ClientConfigResponse>;
    hasError: boolean;
    apmServiceUrl: string,
    apmServiceName: string,
    deployEnvironment: string,
    integrationPlatform: string,
}

class ConfigFreezerService extends FreezerService<IConfigState, typeof InjectedPropName> {
   constructor(){
        super(
            {
                clientConfigResults: managedAjaxUtil.createInitialState(),
                hasError: false,
                apmServiceUrl: '',
                apmServiceName: '',
                deployEnvironment: '',
                integrationPlatform: '',
            },
            InjectedPropName,
        );
    }
    public getDeployEnvironment(): string {
        return this.freezer.get().deployEnvironment;
    }

    public setDeployEnvironment(deployEnvironment: string) {
        this.freezer.get().set({ deployEnvironment: deployEnvironment });
    }
    public getApmServiceName() : string {
        return this.freezer.get().clientConfigResults.data?.apmServiceName || '';
    }
    public getApmServiceUrl() : string {
        return this.freezer.get().clientConfigResults.data?.apmServiceUrl || '';
    }
    public getIntegrationPlatform() : string {
        return this.freezer.get().clientConfigResults.data?.integrationPlatform || '';
    }
    public setApmServiceName(apmServiceName: string)  {
        return this.freezer.get().set({ apmServiceName: apmServiceName });
    }
    public setApmServiceUrl(apmServiceUrl: string)  {
        return this.freezer.get().set({ apmServiceUrl: apmServiceUrl });
    }
    public setIntegrationPlatform(integrationPlatform: string)  {
        return this.freezer.get().set({ integrationPlatform: integrationPlatform });
    }
    public async getClientConfig(): Promise<void | ClientConfigResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'clientConfigResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = ConfigApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                console.log(AdministrativeApi.apiV1ConfigGetClientConfigGet(params))
                
                return AdministrativeApi.apiV1ConfigGetClientConfigGet(params);
            },
            params: {
                xGeotabDatabase: "",
                xGeotabUser: "",
                xGeotabSession: "",
                xGeotabServer: ""
            },
            onOk: (data: ClientConfigResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                if(data.apmServiceName)
                    this.setApmServiceName(data.apmServiceName);
                if(data.apmServiceUrl)
                    this.setApmServiceUrl(data.apmServiceUrl);
                if(data.deployEnvironment)
                    this.setDeployEnvironment(data.deployEnvironment);                
                if(data.integrationPlatform)
                    this.setIntegrationPlatform(data.integrationPlatform);
                }
              
                console.log(data);

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }
}

export const ConfigService = new ConfigFreezerService();
export type IConfigServiceInjectedProps = ReturnType<ConfigFreezerService['getPropsForInjection']>;