import { React, bind, moment } from '../Imports';
import { ClickAwayListener, TextField, CircularProgress, Button, Card, CardContent, MobileStepper } from '../MaterialUIComponents';
import { IEventReviewServiceInjectedProps, EventReviewService, VideoState } from '../state/EventReviewFreezer';
import { EventVideo } from '../components/EventVideo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import SwipeableViews from 'react-swipeable-views';
import { Redirect } from 'react-router';
import { ConfigService, IConfigServiceInjectedProps } from '$State/ConfigFreezerService';
import { GetImageUrl } from '$Utilities/dataModelUtilities';

interface IEventReviewBaseProps {}

type IEventReviewProps = IEventReviewBaseProps & IEventReviewServiceInjectedProps & IConfigServiceInjectedProps;

interface IEventReviewState {
    errorMsg: string;
    token: string;
    passwordHasError: boolean;
    view: string;
    comment: string;
    isFullscreen: boolean;
    isTooltipOpen: boolean;
}

const styles = require('./EventReview.scss') as {
    main: string;
    loginbutton: string;
    content: string;
    centeredText: string;
    buttonContainer: string;
    column: string;
    bottomInfo: string;
    topInfo: string;
    feedback: string;
    discussButton: string;
    flexContainer: string;
    headerTextContainer: string;
    acceptButton: string;
    sendButton: string;
    score: string;
    scoreContainer: string;
    mobileStepper: string;
    removedFromDisplay: string;
    dot: string;
    dotActive: string;
    mainImage: string;
    inputStyle: string;
    placeIcon: string;
    cameraLabel: string;
    postedSpeedContainer: string;
    locationContainer: string;
    eventDescription: string;
    eventType: string;
    eventTypeTooltipContainer: string;
    eventTypeTooltipHoverTarget: string;
    eventTypeTooltipContent: string;
    wedge: string;
};

class _EventReview extends React.Component<IEventReviewProps, IEventReviewState> {
    state: IEventReviewState = {
        errorMsg: '',
        token: '',
        passwordHasError: false,
        view: 'review',
        comment: '',
        isFullscreen: false,
        isTooltipOpen: false,
    };

    async componentDidMount() {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        const token = searchParams.get('token');
        if (token !== null) {
            this.setState({ token });
            // Generates all the initial data
            await this.props.eventReview.getDriverEvent(token);
        }
    }

    @bind
    toggleVideoState(videoId: number) {
        this.props.eventReview.toggleVideoDetailsPlaying(videoId);
    }

    @bind
    discuss() {
        this.setState({ view: 'discuss' });
    }

    @bind
    cancel() {
        this.setState({ view: 'review' });
    }

    @bind
    send() {
        if (this.state.comment.trim() !== '') {
            this.props.eventReview.sendDriverDiscussion(this.state.token, this.state.comment);
        }
    }

    @bind
    commentChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ comment: e.target.value });
    }

    @bind
    acceptEvent() {
        this.props.eventReview.acceptDriverEvent(this.state.token);
    }

    @bind
    videoPlayedToEnd(videoId: number) {
        // video has been played all the way through.
        this.props.eventReview.setVideoStatusToFullyWatched(videoId);
    }

    @bind
    videoStarted(videoId: number) {
        // video has been played all the way through.
        this.props.eventReview.setVideoStatus('Opened', videoId);
    }

    @bind
    toggleFullscreen() {
        this.setState({ isFullscreen: !this.state.isFullscreen });
    }

    @bind
    setCurrentVideo(videoStep: number) {
        this.props.eventReview.setCurrentVideo(videoStep);
    }

    @bind
    videoWasSwiped(index: number, indexLatest: number) {
        this.props.eventReview.togglePlayEnabled(false);
        this.setCurrentVideo(index);
    }

    @bind
    nextVideo() {
        const currentVideoInformation = this.props.eventReview.getCurrentVideo();
        this.props.eventReview.togglePlayEnabled(false);
        this.setCurrentVideo(currentVideoInformation.currentStep + 1);
    }

    @bind
    previousVideo() {
        const currentVideoInformation = this.props.eventReview.getCurrentVideo();
        this.props.eventReview.togglePlayEnabled(false);
        this.setCurrentVideo(currentVideoInformation.currentStep - 1);
    }

    @bind
    formatEventTypeName(type: string | undefined): string {
        if (type === undefined || type === null || type === '') {
            return 'Unknown';
        }
        if (['forwardcollisionwarning', 'forward collision warning'].indexOf(type.toLowerCase()) !== -1) {
            // Forward Collision Warning and Headway Monitoring are also too long for the title card
            return 'FCW';
        }
        if (type.includes('Gforce')) {
            // Cleaning Gforce reference that comes from VideoEventTypeEnum
            type = type.replace('Gforce', '');
        }
        if (type?.includes('RightTurn')) {
            type = type.replace('RightTurn', 'Cornering');
        }
        return String(type)
            .replace(/([A-Z])/g, ' $1')
            .trim();
    }

    @bind
    getEventTypeLabels(labelArray: (string | undefined)[] | undefined): (string | undefined)[] {
        const labelName = [],
            labelCount = [];
        let prev;

        if (labelArray) {
            // https://stackoverflow.com/questions/54839677/fast-way-to-count-and-remove-duplicates-from-an-array
            // count the dups, remove the dups and add the number of occurences for each
            labelArray.sort();
            for (let i = 0; i < labelArray.length; i++) {
                if (labelArray[i] !== prev) {
                    labelName.push(labelArray[i]);
                    labelCount.push(1);
                } else {
                    labelCount[labelCount.length - 1]++;
                }
                prev = labelArray[i];
            }
        }
        const labels = [];
        for (let i = 0; i < labelName.length; i++) {
            if (labelCount[i] != 1) labels.push(labelName[i] + ' (' + labelCount[i] + ')');
            else labels.push(labelName[i]);
        }
        return labels;
    }

    @bind
    getMergedEventTypes(eventTypeEnum?: string): string | undefined {
        switch (eventTypeEnum) {
            case 'HarshRightTurnGforce':
                return 'HarshCorneringGforce';
            case 'GeotabSeatbelt':
                return 'NoSeatbelt';
            case 'GeotabSeatbeltCustom':
                return 'NoSeatbelt';
            case 'LensCovered':
                return 'NoDriver';
            default:
                return eventTypeEnum;
        }
    }

    render() {
        const freezer = this.props.eventReview.getState();
        const {
            playEnabled,
            redirectTo,
            currentVideoInformation,
            videoInformation,
            videoCount,
            eventTime,
            eventType,
            eventDescription,
            score,
            vehicleId,
            cityRegion,
            address,
            driverName,
            showSpeedLimit,
            speedLimit,
            videoEventTypePairings,
            videoEventTypeString,
        } = freezer;
        const currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        const currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;
        const eventTypes = videoEventTypePairings?.map((et) => this.getMergedEventTypes(et.videoEventTypeString) ?? '');
        const eventTypeLabel = this.formatEventTypeName(videoEventTypeString);
        const eventTypeLabels = eventTypes ? this.getEventTypeLabels(eventTypes) : undefined;

        if (redirectTo !== '') {
            return <Redirect to={redirectTo} />;
        }
        return videoCount >= 1 ? (
            <div>
                {this.state.isFullscreen === true ? (
                    <EventVideo
                        videoUrl={currentVideoInformation.videoURL}
                        thumbUrl={currentVideoInformation.thumbNailURL}
                        videoIsPlaying={currentVideoInformation.isPlaying}
                        scrubTo={currentVideoInformation.scrubTime}
                        toggleVideoIsPlaying={() => {
                            this.toggleVideoState(currentVideoInformation.videoId);
                        }}
                        playedToEnd={() => this.videoPlayedToEnd(currentVideoInformation.videoId)}
                        toggleFullscreen={this.toggleFullscreen}
                        isFullscreen={this.state.isFullscreen}
                        playEnabled={true}
                        started={() => this.videoStarted(currentVideoInformation.videoId)}
                    />
                ) : (
                    <div className={styles.main}>
                        <Card className={styles.content}>
                            <CardContent className={styles.centeredText}>
                                <img
                                    className={styles.mainImage}
                                    src={GetImageUrl(
                                        currentImagesBucket ? currentImagesBucket : '',
                                        currentRegion ? currentRegion : '',
                                        'videoProtects.png',
                                    )}
                                />
                                <div className={styles.headerTextContainer}>
                                    <div className={styles.flexContainer}>Driver Event Review</div>
                                    <div className={styles.flexContainer}>Video Safety Coaching</div>
                                </div>
                            </CardContent>

                            <div className={styles.column}>
                                {this.state.view !== 'discuss' ? (
                                    <div>
                                        {videoCount >= 2 ? (
                                            <div>
                                                <SwipeableViews
                                                    enableMouseEvents
                                                    className={styles.mobileStepper}
                                                    index={currentVideoInformation.currentStep}
                                                    onChangeIndex={this.videoWasSwiped}
                                                >
                                                    {videoInformation.map((video: VideoState) => {
                                                        return (
                                                            <div key={'DivContainer' + video.videoId}>
                                                                <EventVideo
                                                                    videoUrl={video.videoURL}
                                                                    key={'eventVideo' + video.videoId}
                                                                    thumbUrl={video.thumbNailURL}
                                                                    videoIsPlaying={video.isPlaying}
                                                                    scrubTo={video.scrubTime}
                                                                    toggleVideoIsPlaying={() => {
                                                                        this.toggleVideoState(video.videoId);
                                                                    }}
                                                                    playedToEnd={() => this.videoPlayedToEnd(video.videoId)}
                                                                    toggleFullscreen={this.toggleFullscreen}
                                                                    isFullscreen={this.state.isFullscreen}
                                                                    playEnabled={playEnabled}
                                                                    started={() => this.videoStarted(video.videoId)}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </SwipeableViews>
                                                <MobileStepper
                                                    classes={{ dot: styles.dot, dotActive: styles.dotActive }}
                                                    className={styles.mobileStepper}
                                                    variant="dots"
                                                    steps={videoCount}
                                                    position="static"
                                                    activeStep={currentVideoInformation.currentStep}
                                                    nextButton={
                                                        <Button
                                                            className={styles.removedFromDisplay}
                                                            size="small"
                                                            disabled={currentVideoInformation.currentStep === videoCount - 1}
                                                            onClick={this.nextVideo}
                                                        >
                                                            Next
                                                        </Button>
                                                    }
                                                    backButton={
                                                        <Button
                                                            className={styles.removedFromDisplay}
                                                            size="small"
                                                            disabled={currentVideoInformation.currentStep === 0}
                                                            onClick={this.previousVideo}
                                                        >
                                                            Previous
                                                        </Button>
                                                    }
                                                    sx={{ backgroundColor: '#FAFAFA' }}
                                                />
                                            </div>
                                        ) : (
                                            <EventVideo
                                                videoUrl={currentVideoInformation.videoURL}
                                                thumbUrl={currentVideoInformation.thumbNailURL}
                                                videoIsPlaying={currentVideoInformation.isPlaying}
                                                scrubTo={currentVideoInformation.scrubTime}
                                                toggleVideoIsPlaying={() => this.toggleVideoState(currentVideoInformation.videoId)}
                                                playedToEnd={() => this.videoPlayedToEnd(currentVideoInformation.videoId)}
                                                toggleFullscreen={this.toggleFullscreen}
                                                isFullscreen={this.state.isFullscreen}
                                                started={() => this.videoStarted(currentVideoInformation.videoId)}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <div className={styles.flexContainer}>
                                        <TextField
                                            inputProps={{
                                                style: { fontSize: '2.4em', lineHeight: '1em' },
                                                maxLength: 2000,
                                            }}
                                            rows={7}
                                            size="medium"
                                            value={this.state.comment}
                                            placeholder="Add feedback here..."
                                            onChange={this.commentChange}
                                            variant="outlined"
                                            multiline
                                            className={styles.feedback}
                                        />
                                    </div>
                                )}
                                <div>
                                    <div className={styles.topInfo}>
                                        <div>
                                            {/* <div><b>{eventType}</b></div> */}
                                            <div className={styles.eventDescription}>
                                                <div className={styles.eventType}>
                                                    {eventTypeLabel || 'Unknown'}
                                                    {eventTypeLabels && eventTypeLabels.length > 1 && (
                                                        <div className={styles.eventTypeTooltipContainer}>
                                                            <FontAwesomeIcon
                                                                icon={faInfoCircle}
                                                                onClick={() => this.setState({ isTooltipOpen: !this.state.isTooltipOpen })}
                                                                className={styles.eventTypeTooltipHoverTarget}
                                                            />
                                                            {this.state.isTooltipOpen && (
                                                                <ClickAwayListener
                                                                    onClickAway={() => {
                                                                        this.setState({ isTooltipOpen: false });
                                                                    }}
                                                                >
                                                                    <div className={styles.eventTypeTooltipContent}>
                                                                        <ul>
                                                                            {eventTypeLabels.map((x, idx) => (
                                                                                <div key={idx}>{x}</div>
                                                                            ))}
                                                                        </ul>
                                                                        <div className={styles.wedge}>
                                                                            <img
                                                                                src={GetImageUrl(
                                                                                    currentImagesBucket ? currentImagesBucket : '',
                                                                                    currentRegion ? currentRegion : '',
                                                                                    'tooltip_wedge.svg',
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </ClickAwayListener>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div>{driverName}</div>
                                            <div>{eventDescription}</div>
                                            <div>{vehicleId}</div>
                                        </div>

                                        {showSpeedLimit && (
                                            <div className={styles.postedSpeedContainer}>
                                                <b>Posted speed</b>
                                                <br />
                                                {speedLimit}
                                            </div>
                                        )}

                                        {/* {score &&
                                                (
                                                    <div className={styles.scoreContainer}>
                                                        <div className={styles.score} style={{ backgroundColor: buttonColors[5] }}>
                                                            {score}
                                                        </div>
                                                    </div>

                                                )} */}
                                    </div>
                                    <div className={styles.bottomInfo}>
                                        <div className={styles.flexContainer}>
                                            <div>{moment(eventTime).format('MMMM Do YYYY')}</div>
                                            <div>{moment(eventTime).format('h:mm:ss a')}</div>
                                        </div>

                                        <div className={styles.locationContainer}>
                                            <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.placeIcon} />
                                            {address && cityRegion ? (
                                                <div className={styles.flexContainer}>
                                                    <div>{address.toUpperCase()}</div>
                                                    <div>{cityRegion.toUpperCase()}</div>
                                                </div>
                                            ) : (
                                                <div>Unknown</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.view !== 'discuss' ? (
                                <div className={styles.buttonContainer}>
                                    <Button variant="contained" className={styles.acceptButton} onClick={this.acceptEvent}>
                                        Accept
                                    </Button>
                                    <Button variant="contained" className={styles.discussButton} onClick={this.discuss}>
                                        Discuss
                                    </Button>
                                </div>
                            ) : (
                                <div className={styles.buttonContainer}>
                                    <Button
                                        variant="contained"
                                        className={styles.sendButton}
                                        onClick={this.send}
                                        disabled={this.state.comment.trim() === ''}
                                    >
                                        Send
                                    </Button>
                                    <Button variant="contained" className={styles.discussButton} onClick={this.cancel}>
                                        Cancel
                                    </Button>
                                </div>
                            )}
                        </Card>
                    </div>
                )}
            </div>
        ) : (
            <div>
                <CircularProgress />
            </div>
        );
    }
}

export const EventReviewPage = EventReviewService.inject(ConfigService.inject(_EventReview));
