// #region Imports
import { React, s } from 'Imports';
import { Theme, withStyles, WithStyles, CSSProperties, ThemeProvider, createTheme, StyledEngineProvider } from 'MaterialUIComponents';

import { cx } from '@videoplatform/css-helpers';
import { getLogger } from '@videoplatform/logging';
import { ISinglePageApplicationProps } from '@videoplatform/react-spa';

import { IApplicationOptions } from './utilities/api';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';
import { PageRouter } from '$Pages/PageRouter';
import { init as initApm } from '@elastic/apm-rum';
// #endregion Imports

// Include the global styles
require('./css/global.scss');

const styles = require('./Application.scss') as {
    active: string;
    link: string;
    logo: string;
    main: string;
    navbar: string;
    page: string;
    pageOuter: string;
    toolbar: string;
};

const theme = createTheme({
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#E0E0E0',
                    color: 'black',
                    '&:hover': { backgroundColor: '#D5D5D5' },
                },
            },
        },
    },
});

const muiStyles = () => ({
    root: {
        flexGrow: 1,
        height: 430,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    } as CSSProperties,
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawerPaper: {
        position: 'relative',
        width: 305,
    } as CSSProperties,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: '0 10px',
        minHeight: 0,
        minWidth: 0, // So the Typography noWrap works
    },
    toolbar: theme.mixins.toolbar,
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

const logger = getLogger('Application');

interface IApplicationState {
    apiOptions: IApplicationOptions;
    selectedEvent: string;
    imagesBucket: string;
    imagesBucketRegion: string;
}

type IApplicationProps = ISinglePageApplicationProps & IConfigServiceInjectedProps & WithStyles<keyof ReturnType<typeof muiStyles>>;

export class _Application extends React.Component<IApplicationProps, IApplicationState> {
    state = {
        apiOptions: {
            // The current api generator can't handle "/" as a base url, so we replace that with a
            // fully qualified base URL with trailing slashes removed
            applicationRoot: this.props.baseUrl,
            apiBaseUrl: s.rtrim(`${this.props.apiBaseUrl}`, '/'),
            wrappedFetch: fetch,
        },
        selectedEvent: '',
        imagesBucket: '',
        imagesBucketRegion: '',
    };

    async componentDidMount(): Promise<void> {
        await this.props.config.getClientConfig();
        const currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        this.setState({ imagesBucket: currentImagesBucket ? currentImagesBucket : '' });

        const currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;
        this.setState({ imagesBucketRegion: currentRegion ? currentRegion : '' });
        const apm = initApm({
            // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
            serviceName: ConfigService.getApmServiceName(), //'ui_vprotect',
            // Set custom APM Server URL
            serverUrl: ConfigService.getApmServiceUrl(),
            // Set service environment (required for sourcemap feature)
            environment: ConfigService.getDeployEnvironment(),
        });
        apm.addLabels({ IntegrationPlatform: ConfigService.getIntegrationPlatform() });
    }

    parseURL(url: string | null | undefined) {
        const parser = document.createElement('a');
        // Let the browser do the work
        parser.href = url === null || url === undefined ? '' : url;
        return {
            protocol: parser.protocol,
            host: parser.host,
            hostname: parser.hostname,
            port: parser.port,
            pathname: parser.pathname,
            search: parser.search,
            hash: parser.hash,
        };
    }

    render() {
        logger.info('Rendering application');
        this.props.setFaviconUrl('https://' + this.state.imagesBucket + '.s3.us-east-2.amazonaws.com/favicon.ico');

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <div className={cx([this.props.classes.root, styles.main])}>
                        <main className={this.props.classes.content} style={{ paddingLeft: 0, paddingRight: 0, minHeight: 0 }}>
                            <div className={this.props.classes.toolbar} style={{ minHeight: 0 }} />
                            <div className={styles.pageOuter}>
                                <div className={styles.page}>
                                    <PageRouter {...this.props} />
                                </div>
                            </div>
                        </main>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

const Application = withStyles(muiStyles)(ConfigService.inject(_Application));

export { Application, IApplicationProps };
