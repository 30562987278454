import { _ } from '../Imports';
import { Dictionary } from 'lodash';

// string is null or empty
export const IsNullOrEmptyString: (s: string) => boolean = (s) => {
    if (s === null || s === undefined || s.trim() === '' || _.isEmpty(s)) {
        return true;
    } else {
        return false;
    }
};

// array is null or empty
export const IsNullOrEmptyArray: (s: any[]) => boolean = (s) => {
    if (s === null || s === undefined || s.length === 0 || _.isEmpty(s)) {
        return true;
    } else {
        return false;
    }
};

// object is null or empty
export const IsNullOrEmptyObject: (s: any) => boolean = (s) => {
    if (s === null || s === undefined || Object.keys(s).length === 0 || _.isEmpty(s)) {
        return true;
    } else {
        return false;
    }
};

// dictionary To String
export const DictionaryToString: (s: Dictionary<string>) => string = (s) => {
    if (IsNullOrEmptyObject(s)) {
        return '';
    } else {
        let result = '{';
        let count = 0;
        for (const key of Object.keys(s)) {
            count++;
            result += '(' + key + ',' + s[key] + ')';
            if (count < Object.keys(s).length) {
                result += ',';
            }
        }
        result += '}';
        return result;
    }
};

// dictionary To String
export const DictionaryContainsKey: (dictionary: Dictionary<string>, key: string) => boolean = (s, k) => {
    if (IsNullOrEmptyObject(s)) {
        return false;
    }
    for (const key of Object.keys(s)) {
        if (k === key) {
            return true;
        }
    }
    return false;
};

// calculate and add skip, take
export const AppendPagingToUrl: (url: string, currentPage: number, itemsPerPage: number) => string = (url, currentPage, itemsPerPage) => {
    const skip: number = (currentPage - 1) * itemsPerPage;
    url += 'skip=' + skip;
    url += '&take=' + itemsPerPage;
    return url;
};

export const GetImageUrl: (imagesBucket: string, imagesBucketRegion: string, fileName: string) => string = (
    imagesBucket,
    region,
    fileName,
) => {
    return 'https://' + imagesBucket + '.s3.' + region + '.amazonaws.com/' + fileName;
};
