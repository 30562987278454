import * as React from 'react';
import * as _ from 'lodash';

export type ReactComponentLike<T> = React.ComponentClass<T> | React.StatelessComponent<T>;

type ComponentWrapper<IOriginalProps extends Record<string, any>, IInjectedProps extends Record<string, any>> = (
    Component: ReactComponentLike<IOriginalProps & IInjectedProps>,
) => React.SFC<IOriginalProps>;

export function withInjectedContext<
    IOriginalProps extends Record<string, any>,
    ContextPayload,
    IPropsWithContext extends Record<string, any>
>(
    Component: ReactComponentLike<IOriginalProps & IPropsWithContext>,
    context: React.Context<ContextPayload>,
    contextTransformer: (contextData: ContextPayload) => IPropsWithContext,
): React.SFC<IOriginalProps> {
    return function BoundComponent(props: IOriginalProps): JSX.Element {
        return (
            <context.Consumer>
                {(value) => {
                    const injectedProps = contextTransformer(value);

                    const combinedProps = _.extend({}, props, injectedProps);

                    return <Component {...combinedProps} />;
                }}
            </context.Consumer>
        );
    };
}
