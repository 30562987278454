import { React, Route, Router, s } from 'Imports'

import { IApplicationProps } from 'Application'
import { ApiContext, IApplicationOptions } from 'utilities/api'

import { HomePage } from '$Pages/Home/Home';
import { EventReviewPage } from "$Pages/EventReview";
import { EventExpiredPage } from "$Pages/EventExpired";
import { DriverEventAcceptedPage } from "$Pages/DriverEventAccepted";
import { DriverDiscussionSentPage } from "$Pages/DriverDiscussionSent";

interface IPageRouterState {
    apiOptions: IApplicationOptions
}

export class PageRouter extends React.Component<IApplicationProps, IPageRouterState> {
    state = {
        apiOptions: {
            // The current api generator can't handle "/" as a base url, so we replace that with a
            // fully qualified base URL with trailing slashes removed
            applicationRoot: this.props.baseUrl,
            apiBaseUrl: s.rtrim(`${this.props.apiBaseUrl}`, "/"),
            wrappedFetch: fetch            
        }
    }
    render(): JSX.Element {
        const { apiOptions } = this.state
        return (
            <Router>
                <ApiContext.Provider value={apiOptions}>
                    <Route
                        path="/"
                        exact={true}
                        render={(props) => {
                            this.props.setPageTitle("Home");
                            return (
                                <HomePage />
                            );
                        }}
                    />

                    <Route
                        path="/eventreview"
                        exact={true}
                        render={(props) => {
                            this.props.setPageTitle("Event Review");
                            return (
                                <EventReviewPage />
                            );
                        }}
                    />

                    <Route
                        path="/eventexpired"
                        exact={true}
                        render={(props) => {
                            this.props.setPageTitle("Event Expired");
                            return (
                                <EventExpiredPage />
                            );
                        }}
                    />

                    <Route
                        path="/drivereventaccepted"
                        exact={true}
                        render={(props) => {
                            this.props.setPageTitle("Driver Event Accepted");
                            return (
                                <DriverEventAcceptedPage />
                            );
                        }}
                    />

                    <Route
                        path="/driverdiscussionsent"
                        exact={true}
                        render={(props) => {
                            this.props.setPageTitle("Driver Discussion Sent");
                            return (
                                <DriverDiscussionSentPage />
                            );
                        }}
                    />
                </ApiContext.Provider>
            </Router>
        )
    }
}
