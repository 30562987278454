import { React, bind } from '../Imports';
import ReactPlayer from 'react-player';
import { withStyles, Slider } from '../MaterialUIComponents';

const scssStyles = require('./../css/settings.scss');

interface IEventVideoProps {
    videoUrl?: string;
    thumbUrl?: string;
    toggleVideoIsPlaying: () => void;
    videoIsPlaying: boolean;
    scrubTo: number;
    playedToEnd: () => void;
    toggleFullscreen: () => void;
    isFullscreen: boolean;
    playEnabled?: boolean;
    started: () => void;
}

interface IEventVideoState {
    progress: number;
    playing: boolean;
}

const styles = require('./EventVideo.scss') as {
    main: string;
    loadingContainer: string;
    thumbnail: string;
    hideVideo: string;
    iconOverlay: string;
    playIcon: string;
    playOffset: string;
    controlOverlay: string;
    videoPlayPause: string;
    playPauseIcon: string;
    videoSeek: string;
    download: string;
    fullscreen: string;
    fullscreenButton: string;
};

const StyledSlider = withStyles({
    root: {
        margin: '12px 0 0',
    },
    colorPrimary: {
        color: scssStyles.mainBlue,
    },

    thumb: {
        backgroundColor: '#FFFFFF',
    },
})(Slider);
export class EventVideo extends React.Component<IEventVideoProps, IEventVideoState> {
    state = {
        progress: 0,
        playing: false,
    };

    player: any;

    @bind
    handlePlayPause(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
        this.setState({
            playing: !this.state.playing,
        });
        this.props.toggleVideoIsPlaying();
    }

    @bind
    handleProgressUpdate(playerState: any) {
        this.setState({
            progress: playerState.played,
        });
    }

    componentDidUpdate(prevProps: IEventVideoProps) {
        if (this.props.scrubTo !== 0 && this.props.scrubTo !== prevProps.scrubTo) {
            this.player.seekTo(this.props.scrubTo);
        }
    }

    @bind
    toggleFullscreen() {
        this.props.toggleFullscreen();
    }

    render() {
        const videoUrl = this.props.videoUrl;
        const { videoIsPlaying } = this.props;

        return (
            <div className={this.props.isFullscreen === true ? styles.fullscreen : styles.main}>
                <ReactPlayer
                    url={videoUrl}
                    ref={(player) => (this.player = player)}
                    playing={videoIsPlaying}
                    controls={true}
                    width="100%"
                    height="100%"
                    progressInterval={500}
                    onClick={this.handlePlayPause}
                    onProgress={this.handleProgressUpdate}
                    onEnded={this.props.playedToEnd}
                    onStart={this.props.started}
                    muted={true}
                />
            </div>
        );
    }
}
