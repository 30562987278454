import { React } from "../../Imports";
import { PageHeader } from "../../components/Shared/PageHeader";

interface IHomeBaseProps {
}

type IHomeProps = IHomeBaseProps;

interface IHomeState {
}

const styles = require("./Home.scss") as {
    main: string,
    contentContainer: string,
    content: string,
    filterContainer: string;
};

class _HomePage extends React.Component<IHomeProps, IHomeState> {
    state: IHomeState = {
    };

    render() {
        return (
            <div className={styles.main}>
                <PageHeader pageTitle={"Home"} />
                <div className={styles.contentContainer}>
                    <div className={styles.filterContainer}>
                    </div>
                    <div className={styles.content}>
                        Driver App Home Page
                    </div>
                </div>
            </div>
        );
    }
}

export const HomePage = _HomePage;
