/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as querystring from "querystring";
import * as url from "url";

const isomorphicFetch = fetch;
const assign: (target: any, ...sources: any[]) => any = (Object as any).assign;

interface Dictionary<T> { [index: string]: T; }
export interface FetchAPI { (url: string, init?: any): Promise<any>; }

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

export interface FetchArgs {
    url: string;
    options: any;
}

export class BaseAPI {
    basePath: string;
    fetch: FetchAPI;

    constructor(fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) {
        this.basePath = basePath;
        this.fetch = fetch;
    }
};

export interface Assembly {
    "definedTypes"?: Array<TypeInfo>;
    "exportedTypes"?: Array<Type>;
    "codeBase"?: string;
    "entryPoint"?: MethodInfo;
    "fullName"?: string;
    "imageRuntimeVersion"?: string;
    "isDynamic"?: boolean;
    "location"?: string;
    "reflectionOnly"?: boolean;
    "isCollectible"?: boolean;
    "isFullyTrusted"?: boolean;
    "customAttributes"?: Array<CustomAttributeData>;
    "escapedCodeBase"?: string;
    "manifestModule"?: Module;
    "modules"?: Array<Module>;
    "globalAssemblyCache"?: boolean;
    "hostContext"?: number;
    "securityRuleSet"?: SecurityRuleSet;
}

export type CallingConventions = "Standard" | "VarArgs" | "Any" | "HasThis" | "ExplicitThis";

export interface CameraRequestResponse {
    "id"?: number;
    "cameraDeviceId"?: number;
    "requestDate"?: Date;
    "commandPayloadJson"?: string;
    "completionDate"?: Date;
    "uploadStrategy"?: string;
    "command"?: string;
    "requestStepId"?: VideoEventPipelineStepEnum;
    "requestStepDescriptionId"?: number;
    "claimToken"?: string;
    "requestedBy"?: string;
    "cameraRequestTypeId"?: CameraRequestTypeEnum;
    "requestStepDescription"?: VideoEventPipelineStepDescriptionResponse;
}

export type CameraRequestTypeEnum = "Log" | "Picture" | "Video";

export interface ClientConfigResponse {
    "imagesBucket"?: string;
    "imagesBucketRegion"?: string;
    "apmServiceUrl"?: string;
    "apmServiceName"?: string;
    "deployEnvironment"?: string;
    "integrationPlatform"?: string;
}

export interface ConstructorInfo {
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "attributes"?: MethodAttributes;
    "methodImplementationFlags"?: MethodImplAttributes;
    "callingConvention"?: CallingConventions;
    "isAbstract"?: boolean;
    "isConstructor"?: boolean;
    "isFinal"?: boolean;
    "isHideBySig"?: boolean;
    "isSpecialName"?: boolean;
    "isStatic"?: boolean;
    "isVirtual"?: boolean;
    "isAssembly"?: boolean;
    "isFamily"?: boolean;
    "isFamilyAndAssembly"?: boolean;
    "isFamilyOrAssembly"?: boolean;
    "isPrivate"?: boolean;
    "isPublic"?: boolean;
    "isConstructedGenericMethod"?: boolean;
    "isGenericMethod"?: boolean;
    "isGenericMethodDefinition"?: boolean;
    "containsGenericParameters"?: boolean;
    "methodHandle"?: RuntimeMethodHandle;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
    "memberType"?: MemberTypes;
}

export interface CustomAttributeData {
    "attributeType"?: Type;
    "constructor"?: ConstructorInfo;
    "constructorArguments"?: Array<CustomAttributeTypedArgument>;
    "namedArguments"?: Array<CustomAttributeNamedArgument>;
}

export interface CustomAttributeNamedArgument {
    "memberInfo"?: MemberInfo;
    "typedValue"?: CustomAttributeTypedArgument;
    "memberName"?: string;
    "isField"?: boolean;
}

export interface CustomAttributeTypedArgument {
    "argumentType"?: Type;
}

export interface DriverDiscussionRequest {
    "token"?: string;
    "discussion"?: string;
}

export type EventAttributes = "None" | "SpecialName" | "RTSpecialName" | "ReservedMask";

export interface EventInfo {
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "memberType"?: MemberTypes;
    "attributes"?: EventAttributes;
    "isSpecialName"?: boolean;
    "addMethod"?: MethodInfo;
    "removeMethod"?: MethodInfo;
    "raiseMethod"?: MethodInfo;
    "isMulticast"?: boolean;
    "eventHandlerType"?: Type;
}

export interface Exception {
    "targetSite"?: MethodBase;
    "message"?: string;
    "data"?: any;
    "innerException"?: Exception;
    "helpLink"?: string;
    "source"?: string;
    "hResult"?: number;
    "stackTrace"?: string;
}

export type FieldAttributes = "PrivateScope" | "Private" | "FamANDAssem" | "Assembly" | "Family" | "FamORAssem" | "Public" | "FieldAccessMask" | "Static" | "InitOnly" | "Literal" | "NotSerialized" | "HasFieldRVA" | "SpecialName" | "RTSpecialName" | "HasFieldMarshal" | "PinvokeImpl" | "HasDefault" | "ReservedMask";

export interface FieldInfo {
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "memberType"?: MemberTypes;
    "attributes"?: FieldAttributes;
    "fieldType"?: Type;
    "isInitOnly"?: boolean;
    "isLiteral"?: boolean;
    "isNotSerialized"?: boolean;
    "isPinvokeImpl"?: boolean;
    "isSpecialName"?: boolean;
    "isStatic"?: boolean;
    "isAssembly"?: boolean;
    "isFamily"?: boolean;
    "isFamilyAndAssembly"?: boolean;
    "isFamilyOrAssembly"?: boolean;
    "isPrivate"?: boolean;
    "isPublic"?: boolean;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
    "fieldHandle"?: RuntimeFieldHandle;
}

export type FileUploadExtensionEnum = "Mp4" | "Jpeg" | "Mp5" | "Txt" | "Webm" | "Ogv" | "Png";

export type FileUploadTypeEnum = "FrontVideo" | "RearVideo" | "SideVideo" | "Log" | "AltVideo";

export type GenericParameterAttributes = "None" | "Covariant" | "Contravariant" | "VarianceMask" | "ReferenceTypeConstraint" | "NotNullableValueTypeConstraint" | "DefaultConstructorConstraint" | "SpecialConstraintMask";

export interface HealthCheckResponse {
}

export interface HealthCheckResponseResponseBase {
    "success"?: boolean;
    "error"?: Exception;
    "totalCount"?: number;
    "data"?: HealthCheckResponse;
}

export interface ICustomAttributeProvider {
}

export interface IntPtr {
}

export type LayoutKind = "Sequential" | "Explicit" | "Auto";

export interface MemberInfo {
    "memberType"?: MemberTypes;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "name"?: string;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
}

export type MemberTypes = "Constructor" | "Event" | "Field" | "Method" | "Property" | "TypeInfo" | "Custom" | "NestedType" | "All";

export type MethodAttributes = "ReuseSlot" | "PrivateScope" | "Private" | "FamANDAssem" | "Assembly" | "Family" | "FamORAssem" | "Public" | "MemberAccessMask" | "UnmanagedExport" | "Static" | "Final" | "Virtual" | "HideBySig" | "NewSlot" | "VtableLayoutMask" | "CheckAccessOnOverride" | "Abstract" | "SpecialName" | "RTSpecialName" | "PinvokeImpl" | "HasSecurity" | "RequireSecObject" | "ReservedMask";

export interface MethodBase {
    "memberType"?: MemberTypes;
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "attributes"?: MethodAttributes;
    "methodImplementationFlags"?: MethodImplAttributes;
    "callingConvention"?: CallingConventions;
    "isAbstract"?: boolean;
    "isConstructor"?: boolean;
    "isFinal"?: boolean;
    "isHideBySig"?: boolean;
    "isSpecialName"?: boolean;
    "isStatic"?: boolean;
    "isVirtual"?: boolean;
    "isAssembly"?: boolean;
    "isFamily"?: boolean;
    "isFamilyAndAssembly"?: boolean;
    "isFamilyOrAssembly"?: boolean;
    "isPrivate"?: boolean;
    "isPublic"?: boolean;
    "isConstructedGenericMethod"?: boolean;
    "isGenericMethod"?: boolean;
    "isGenericMethodDefinition"?: boolean;
    "containsGenericParameters"?: boolean;
    "methodHandle"?: RuntimeMethodHandle;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
}

export type MethodImplAttributes = "IL" | "Managed" | "Native" | "OPTIL" | "Runtime" | "CodeTypeMask" | "Unmanaged" | "ManagedMask" | "NoInlining" | "ForwardRef" | "Synchronized" | "NoOptimization" | "PreserveSig" | "AggressiveInlining" | "AggressiveOptimization" | "InternalCall" | "MaxMethodImplVal";

export interface MethodInfo {
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "attributes"?: MethodAttributes;
    "methodImplementationFlags"?: MethodImplAttributes;
    "callingConvention"?: CallingConventions;
    "isAbstract"?: boolean;
    "isConstructor"?: boolean;
    "isFinal"?: boolean;
    "isHideBySig"?: boolean;
    "isSpecialName"?: boolean;
    "isStatic"?: boolean;
    "isVirtual"?: boolean;
    "isAssembly"?: boolean;
    "isFamily"?: boolean;
    "isFamilyAndAssembly"?: boolean;
    "isFamilyOrAssembly"?: boolean;
    "isPrivate"?: boolean;
    "isPublic"?: boolean;
    "isConstructedGenericMethod"?: boolean;
    "isGenericMethod"?: boolean;
    "isGenericMethodDefinition"?: boolean;
    "containsGenericParameters"?: boolean;
    "methodHandle"?: RuntimeMethodHandle;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
    "memberType"?: MemberTypes;
    "returnParameter"?: ParameterInfo;
    "returnType"?: Type;
    "returnTypeCustomAttributes"?: ICustomAttributeProvider;
}

export interface Module {
    "assembly"?: Assembly;
    "fullyQualifiedName"?: string;
    "name"?: string;
    "mdStreamVersion"?: number;
    "moduleVersionId"?: string;
    "scopeName"?: string;
    "moduleHandle"?: ModuleHandle;
    "customAttributes"?: Array<CustomAttributeData>;
    "metadataToken"?: number;
}

export interface ModuleHandle {
    "mdStreamVersion"?: number;
}

export type ParameterAttributes = "None" | "In" | "Out" | "Lcid" | "Retval" | "Optional" | "HasDefault" | "HasFieldMarshal" | "Reserved3" | "Reserved4" | "ReservedMask";

export interface ParameterInfo {
    "attributes"?: ParameterAttributes;
    "member"?: MemberInfo;
    "name"?: string;
    "parameterType"?: Type;
    "position"?: number;
    "isIn"?: boolean;
    "isLcid"?: boolean;
    "isOptional"?: boolean;
    "isOut"?: boolean;
    "isRetval"?: boolean;
    "hasDefaultValue"?: boolean;
    "customAttributes"?: Array<CustomAttributeData>;
    "metadataToken"?: number;
}

export interface ProcessControlMessage {
    "messageId"?: string;
    "queueName"?: string;
    "processId"?: string;
    "tenantId"?: string;
    "targetDataKey"?: string;
    "priority"?: number;
    "processData"?: { [key: string]: string; };
}

export type PropertyAttributes = "None" | "SpecialName" | "RTSpecialName" | "HasDefault" | "Reserved2" | "Reserved3" | "Reserved4" | "ReservedMask";

export interface PropertyInfo {
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "memberType"?: MemberTypes;
    "propertyType"?: Type;
    "attributes"?: PropertyAttributes;
    "isSpecialName"?: boolean;
    "canRead"?: boolean;
    "canWrite"?: boolean;
    "getMethod"?: MethodInfo;
    "setMethod"?: MethodInfo;
}

export interface RuntimeFieldHandle {
    "value"?: IntPtr;
}

export interface RuntimeMethodHandle {
    "value"?: IntPtr;
}

export interface RuntimeTypeHandle {
    "value"?: IntPtr;
}

export type SecurityRuleSet = "None" | "Level1" | "Level2";

export interface SpeedGraphPointResponse {
    "id"?: number;
    "speed"?: number;
    "time"?: Date;
    "videoEventId"?: number;
}

export interface StructLayoutAttribute {
    "value"?: LayoutKind;
}

export interface Type {
    "name"?: string;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "isInterface"?: boolean;
    "memberType"?: MemberTypes;
    "namespace"?: string;
    "assemblyQualifiedName"?: string;
    "fullName"?: string;
    "assembly"?: Assembly;
    "module"?: Module;
    "isNested"?: boolean;
    "declaringType"?: Type;
    "declaringMethod"?: MethodBase;
    "reflectedType"?: Type;
    "underlyingSystemType"?: Type;
    "isTypeDefinition"?: boolean;
    "isArray"?: boolean;
    "isByRef"?: boolean;
    "isPointer"?: boolean;
    "isConstructedGenericType"?: boolean;
    "isGenericParameter"?: boolean;
    "isGenericTypeParameter"?: boolean;
    "isGenericMethodParameter"?: boolean;
    "isGenericType"?: boolean;
    "isGenericTypeDefinition"?: boolean;
    "isSZArray"?: boolean;
    "isVariableBoundArray"?: boolean;
    "isByRefLike"?: boolean;
    "hasElementType"?: boolean;
    "genericTypeArguments"?: Array<Type>;
    "genericParameterPosition"?: number;
    "genericParameterAttributes"?: GenericParameterAttributes;
    "attributes"?: TypeAttributes;
    "isAbstract"?: boolean;
    "isImport"?: boolean;
    "isSealed"?: boolean;
    "isSpecialName"?: boolean;
    "isClass"?: boolean;
    "isNestedAssembly"?: boolean;
    "isNestedFamANDAssem"?: boolean;
    "isNestedFamily"?: boolean;
    "isNestedFamORAssem"?: boolean;
    "isNestedPrivate"?: boolean;
    "isNestedPublic"?: boolean;
    "isNotPublic"?: boolean;
    "isPublic"?: boolean;
    "isAutoLayout"?: boolean;
    "isExplicitLayout"?: boolean;
    "isLayoutSequential"?: boolean;
    "isAnsiClass"?: boolean;
    "isAutoClass"?: boolean;
    "isUnicodeClass"?: boolean;
    "isCOMObject"?: boolean;
    "isContextful"?: boolean;
    "isEnum"?: boolean;
    "isMarshalByRef"?: boolean;
    "isPrimitive"?: boolean;
    "isValueType"?: boolean;
    "isSignatureType"?: boolean;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
    "structLayoutAttribute"?: StructLayoutAttribute;
    "typeInitializer"?: ConstructorInfo;
    "typeHandle"?: RuntimeTypeHandle;
    "guid"?: string;
    "baseType"?: Type;
    "isSerializable"?: boolean;
    "containsGenericParameters"?: boolean;
    "isVisible"?: boolean;
}

export type TypeAttributes = "NotPublic" | "AutoLayout" | "AnsiClass" | "Class" | "Public" | "NestedPublic" | "NestedPrivate" | "NestedFamily" | "NestedAssembly" | "NestedFamANDAssem" | "NestedFamORAssem" | "VisibilityMask" | "SequentialLayout" | "ExplicitLayout" | "LayoutMask" | "Interface" | "ClassSemanticsMask" | "Abstract" | "Sealed" | "SpecialName" | "RTSpecialName" | "Import" | "Serializable" | "WindowsRuntime" | "UnicodeClass" | "AutoClass" | "CustomFormatClass" | "StringFormatMask" | "HasSecurity" | "ReservedMask" | "BeforeFieldInit" | "CustomFormatMask";

export interface TypeInfo {
    "name"?: string;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "isInterface"?: boolean;
    "memberType"?: MemberTypes;
    "namespace"?: string;
    "assemblyQualifiedName"?: string;
    "fullName"?: string;
    "assembly"?: Assembly;
    "module"?: Module;
    "isNested"?: boolean;
    "declaringType"?: Type;
    "declaringMethod"?: MethodBase;
    "reflectedType"?: Type;
    "underlyingSystemType"?: Type;
    "isTypeDefinition"?: boolean;
    "isArray"?: boolean;
    "isByRef"?: boolean;
    "isPointer"?: boolean;
    "isConstructedGenericType"?: boolean;
    "isGenericParameter"?: boolean;
    "isGenericTypeParameter"?: boolean;
    "isGenericMethodParameter"?: boolean;
    "isGenericType"?: boolean;
    "isGenericTypeDefinition"?: boolean;
    "isSZArray"?: boolean;
    "isVariableBoundArray"?: boolean;
    "isByRefLike"?: boolean;
    "hasElementType"?: boolean;
    "genericTypeArguments"?: Array<Type>;
    "genericParameterPosition"?: number;
    "genericParameterAttributes"?: GenericParameterAttributes;
    "attributes"?: TypeAttributes;
    "isAbstract"?: boolean;
    "isImport"?: boolean;
    "isSealed"?: boolean;
    "isSpecialName"?: boolean;
    "isClass"?: boolean;
    "isNestedAssembly"?: boolean;
    "isNestedFamANDAssem"?: boolean;
    "isNestedFamily"?: boolean;
    "isNestedFamORAssem"?: boolean;
    "isNestedPrivate"?: boolean;
    "isNestedPublic"?: boolean;
    "isNotPublic"?: boolean;
    "isPublic"?: boolean;
    "isAutoLayout"?: boolean;
    "isExplicitLayout"?: boolean;
    "isLayoutSequential"?: boolean;
    "isAnsiClass"?: boolean;
    "isAutoClass"?: boolean;
    "isUnicodeClass"?: boolean;
    "isCOMObject"?: boolean;
    "isContextful"?: boolean;
    "isEnum"?: boolean;
    "isMarshalByRef"?: boolean;
    "isPrimitive"?: boolean;
    "isValueType"?: boolean;
    "isSignatureType"?: boolean;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
    "structLayoutAttribute"?: StructLayoutAttribute;
    "typeInitializer"?: ConstructorInfo;
    "typeHandle"?: RuntimeTypeHandle;
    "guid"?: string;
    "baseType"?: Type;
    "isSerializable"?: boolean;
    "containsGenericParameters"?: boolean;
    "isVisible"?: boolean;
    "genericTypeParameters"?: Array<Type>;
    "declaredConstructors"?: Array<ConstructorInfo>;
    "declaredEvents"?: Array<EventInfo>;
    "declaredFields"?: Array<FieldInfo>;
    "declaredMembers"?: Array<MemberInfo>;
    "declaredMethods"?: Array<MethodInfo>;
    "declaredNestedTypes"?: Array<TypeInfo>;
    "declaredProperties"?: Array<PropertyInfo>;
    "implementedInterfaces"?: Array<Type>;
}

export interface UserResponse {
    "id"?: string;
    "integrationProviderSourceId"?: string;
    "displayName"?: string;
    "email"?: string;
}

export interface VideoAssetResponse {
    "id"?: number;
    "creationDate"?: Date;
    "fileUploadExtensionId"?: FileUploadExtensionEnum;
    "fileUploadTypeId"?: FileUploadTypeEnum;
    "fileUploadTypeDisplayName"?: string;
    "fileUploadId"?: number;
    "type"?: VideoAssetTypeEnum;
    "videoAssetStorageId"?: VideoAssetStorageEnum;
    "storageDetailsJson"?: string;
    "status"?: VideoAssetStatusEnum;
    "aspectRatio"?: string;
}

export type VideoAssetStatusEnum = "Idle" | "CheckOut" | "Generating" | "CheckIn" | "Success" | "Failed";

export type VideoAssetStorageEnum = "GeotabApi" | "GcpFs" | "Controller";

export type VideoAssetTypeEnum = "Upload" | "Full" | "Thumbnail" | "Transcode" | "Preview";

export interface VideoEventActionResponse {
    "id"?: number;
    "actionType"?: VideoEventActionTypeEnum;
    "actionDescription"?: string;
    "actionDate"?: Date;
    "createdBy"?: UserResponse;
}

export type VideoEventActionTypeEnum = "ScoreChanged" | "EventFlagged" | "VideoDownloaded" | "EventDismissed" | "EventCompleted" | "EventReopened" | "PlayedVideo" | "EventStatusChanged" | "CommentAdded" | "CommentDeleted" | "CommentEdited" | "ActionTakenStatus" | "DriverClickedLink" | "DriverStartedWatchingVideo" | "DriverCompletedWatchingVideo" | "EventUnflagged" | "EmailedDriver" | "TextedDriver" | "VideoRecallPromoted" | "DriverChanged" | "VideosWatched";

export type VideoEventAdminResolutionEnum = "ReviewedWithDriver" | "DisciplinedDriver" | "SubmittedToInsurance" | "SavedForFutureReference" | "TerminatedDriver" | "Other";

export type VideoEventInitiatorEnum = "GeotabEventETLProcessor" | "Manual" | "IntegrationTest";

export interface VideoEventPipelineStepDescriptionResponse {
    "id"?: number;
    "displayName"?: string;
    "sysName"?: string;
    "description"?: string;
}

export type VideoEventPipelineStepEnum = "Idle" | "CameraRequest" | "UploadWait" | "VideoProcessing" | "Success" | "Failed" | "CameraPrepare" | "PreparedIdle" | "UploadedIdle" | "CameraDownload" | "DownloadedIdle" | "Purged";

export interface VideoEventResponse {
    "id"?: number;
    "geotabEventId"?: string;
    "videoEventInitiator"?: VideoEventInitiatorEnum;
    "videoEventType"?: Array<number>;
    "videoEventTypePairings"?: Array<VideoEventTypePairingResponse>;
    "driverName"?: string;
    "driverId"?: string;
    "driverTelematicUserId"?: string;
    "driverEmail"?: string;
    "eventStartDate"?: Date;
    "eventEndDate"?: Date;
    "eventDurationSeconds"?: number;
    "location"?: string;
    "addressStreetNumber"?: string;
    "addressRegion"?: string;
    "addressStreetName"?: string;
    "addressPostalCode"?: string;
    "addressCityName"?: string;
    "kphStart"?: number;
    "kphEnd"?: number;
    "vehicleDescription"?: string;
    "integrationPartnerVehicleId"?: string;
    "licensePlateNumber"?: string;
    "licensePlateState"?: string;
    "latitude"?: number;
    "longitude"?: number;
    "workflowStep"?: VideoEventPipelineStepEnum;
    "workflowStepDescription"?: string;
    "requestedBy"?: string;
    "requestedOn"?: Date;
    "videoAsset"?: Array<VideoAssetResponse>;
    "telematicDeviceId"?: number;
    "telematicDeviceSourceId"?: string;
    "telematicDeviceName"?: string;
    "videoEventTypeString"?: string;
    "actionHistory"?: Array<VideoEventActionResponse>;
    "isFlagged"?: boolean;
    "flaggedByUsers"?: Array<UserResponse>;
    "score"?: number;
    "videoEventStatus"?: VideoEventWorkflowStatusIdEnum;
    "adminResolution"?: VideoEventAdminResolutionEnum;
    "cameraRequest"?: CameraRequestResponse;
    "speedGraphPoints"?: Array<SpeedGraphPointResponse>;
    "eventJson"?: string;
    "cameraDeviceSerialNumber"?: string;
}

export interface VideoEventResponseIEnumerablePagedResponseBase {
    "success"?: boolean;
    "error"?: Exception;
    "totalCount"?: number;
    "data"?: Array<VideoEventResponse>;
    "currentPage"?: number;
    "itemsPerPage"?: number;
    "totalPages"?: number;
}

export interface VideoEventTypePairingResponse {
    "videoEventTypeString"?: string;
    "videoEventTypeIconName"?: string;
    "startDate"?: Date;
    "endDate"?: Date;
}

export type VideoEventWorkflowStatusIdEnum = "New" | "In_Progress" | "Dismissed" | "Completed" | "Sent_To_Driver" | "No_Response" | "Driver_Accepted" | "Driver_Disputed" | "Deleted";



/**
 * ApiEndpointApi - fetch parameter creator
 */
export const ApiEndpointApiFetchParamCreator = {
    /** 
     */
    apiHelloGet(options?: any): FetchArgs {
        const baseUrl = `/api/hello`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiProcessControlMessageByNamePost(params: {  "body"?: ProcessControlMessage; }, options?: any): FetchArgs {
        const baseUrl = `/api/ProcessControlMessageByName`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ApiEndpointApi - functional programming interface
 */
export const ApiEndpointApiFp = {
    /** 
     */
    apiHelloGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
        const fetchArgs = ApiEndpointApiFetchParamCreator.apiHelloGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiProcessControlMessageByNamePost(params: { "body"?: ProcessControlMessage;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ApiEndpointApiFetchParamCreator.apiProcessControlMessageByNamePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ApiEndpointApi - object-oriented interface
 */
export class ApiEndpointApi extends BaseAPI {
    /** 
     */
    apiHelloGet(options?: any) {
        return ApiEndpointApiFp.apiHelloGet(options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiProcessControlMessageByNamePost(params: {  "body"?: ProcessControlMessage; }, options?: any) {
        return ApiEndpointApiFp.apiProcessControlMessageByNamePost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ApiEndpointApi - factory interface
 */
export const ApiEndpointApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         */
        apiHelloGet(options?: any) {
            return ApiEndpointApiFp.apiHelloGet(options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiProcessControlMessageByNamePost(params: {  "body"?: ProcessControlMessage; }, options?: any) {
            return ApiEndpointApiFp.apiProcessControlMessageByNamePost(params, options)(fetch, basePath);
        },
    };
};


/**
 * ConfigApi - fetch parameter creator
 */
export const ConfigApiFetchParamCreator = {
    /** 
     */
    apiV1ConfigGetClientConfigGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/config/getClientConfig`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ConfigApi - functional programming interface
 */
export const ConfigApiFp = {
    /** 
     */
    apiV1ConfigGetClientConfigGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientConfigResponse> {
        const fetchArgs = ConfigApiFetchParamCreator.apiV1ConfigGetClientConfigGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ConfigApi - object-oriented interface
 */
export class ConfigApi extends BaseAPI {
    /** 
     */
    apiV1ConfigGetClientConfigGet(options?: any) {
        return ConfigApiFp.apiV1ConfigGetClientConfigGet(options)(this.fetch, this.basePath);
    }
};

/**
 * ConfigApi - factory interface
 */
export const ConfigApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         */
        apiV1ConfigGetClientConfigGet(options?: any) {
            return ConfigApiFp.apiV1ConfigGetClientConfigGet(options)(fetch, basePath);
        },
    };
};


/**
 * DriverEventApi - fetch parameter creator
 */
export const DriverEventApiFetchParamCreator = {
    /** 
     * @param token 
     */
    apiV1AcceptdrivereventGet(params: {  "token"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/acceptdriverevent`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "token": params["token"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param token 
     * @param videoEventHistoryAction 
     * @param camera 
     */
    apiV1AddvideoeventhistoryentryPost(params: {  "token"?: string; "videoEventHistoryAction"?: string; "camera"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/addvideoeventhistoryentry`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "token": params["token"],
            "videoEventHistoryAction": params["videoEventHistoryAction"],
            "camera": params["camera"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1SenddriverdiscussionPost(params: {  "body"?: DriverDiscussionRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/senddriverdiscussion`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param token 
     */
    apiV1VerifyemaildrivereventGet(params: {  "token"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/verifyemaildriverevent`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "token": params["token"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * DriverEventApi - functional programming interface
 */
export const DriverEventApiFp = {
    /** 
     * @param token 
     */
    apiV1AcceptdrivereventGet(params: { "token"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = DriverEventApiFetchParamCreator.apiV1AcceptdrivereventGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param token 
     * @param videoEventHistoryAction 
     * @param camera 
     */
    apiV1AddvideoeventhistoryentryPost(params: { "token"?: string; "videoEventHistoryAction"?: string; "camera"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = DriverEventApiFetchParamCreator.apiV1AddvideoeventhistoryentryPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1SenddriverdiscussionPost(params: { "body"?: DriverDiscussionRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = DriverEventApiFetchParamCreator.apiV1SenddriverdiscussionPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param token 
     */
    apiV1VerifyemaildrivereventGet(params: { "token"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VideoEventResponseIEnumerablePagedResponseBase> {
        const fetchArgs = DriverEventApiFetchParamCreator.apiV1VerifyemaildrivereventGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * DriverEventApi - object-oriented interface
 */
export class DriverEventApi extends BaseAPI {
    /** 
     * @param token 
     */
    apiV1AcceptdrivereventGet(params: {  "token"?: string; }, options?: any) {
        return DriverEventApiFp.apiV1AcceptdrivereventGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param token 
     * @param videoEventHistoryAction 
     * @param camera 
     */
    apiV1AddvideoeventhistoryentryPost(params: {  "token"?: string; "videoEventHistoryAction"?: string; "camera"?: string; }, options?: any) {
        return DriverEventApiFp.apiV1AddvideoeventhistoryentryPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1SenddriverdiscussionPost(params: {  "body"?: DriverDiscussionRequest; }, options?: any) {
        return DriverEventApiFp.apiV1SenddriverdiscussionPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param token 
     */
    apiV1VerifyemaildrivereventGet(params: {  "token"?: string; }, options?: any) {
        return DriverEventApiFp.apiV1VerifyemaildrivereventGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * DriverEventApi - factory interface
 */
export const DriverEventApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param token 
         */
        apiV1AcceptdrivereventGet(params: {  "token"?: string; }, options?: any) {
            return DriverEventApiFp.apiV1AcceptdrivereventGet(params, options)(fetch, basePath);
        },
        /** 
         * @param token 
         * @param videoEventHistoryAction 
         * @param camera 
         */
        apiV1AddvideoeventhistoryentryPost(params: {  "token"?: string; "videoEventHistoryAction"?: string; "camera"?: string; }, options?: any) {
            return DriverEventApiFp.apiV1AddvideoeventhistoryentryPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1SenddriverdiscussionPost(params: {  "body"?: DriverDiscussionRequest; }, options?: any) {
            return DriverEventApiFp.apiV1SenddriverdiscussionPost(params, options)(fetch, basePath);
        },
        /** 
         * @param token 
         */
        apiV1VerifyemaildrivereventGet(params: {  "token"?: string; }, options?: any) {
            return DriverEventApiFp.apiV1VerifyemaildrivereventGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * HealthyApi - fetch parameter creator
 */
export const HealthyApiFetchParamCreator = {
    /** 
     * Create an empty response to allow external actors to verify if application is online
     */
    apiV1HealthDriverappGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/health/driverapp`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * HealthyApi - functional programming interface
 */
export const HealthyApiFp = {
    /** 
     * Create an empty response to allow external actors to verify if application is online
     */
    apiV1HealthDriverappGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HealthCheckResponseResponseBase> {
        const fetchArgs = HealthyApiFetchParamCreator.apiV1HealthDriverappGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * HealthyApi - object-oriented interface
 */
export class HealthyApi extends BaseAPI {
    /** 
     * Create an empty response to allow external actors to verify if application is online
     */
    apiV1HealthDriverappGet(options?: any) {
        return HealthyApiFp.apiV1HealthDriverappGet(options)(this.fetch, this.basePath);
    }
};

/**
 * HealthyApi - factory interface
 */
export const HealthyApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Create an empty response to allow external actors to verify if application is online
         */
        apiV1HealthDriverappGet(options?: any) {
            return HealthyApiFp.apiV1HealthDriverappGet(options)(fetch, basePath);
        },
    };
};
