import { _, FreezerService, managedAjaxUtil, IAjaxState } from "../Imports";
import { DriverEventApiFactory, FileUploadTypeEnum, VideoAssetTypeEnum, DriverDiscussionRequest, VideoEventActionTypeEnum, VideoAssetResponse, VideoEventTypePairingResponse } from "../generated/api";
import * as MediaUrlBuilder from '../components/Shared/MediaUrlBuilder';

const InjectedPropName = "eventReview";
export interface VideoState{
  videoId:number;
  scrubTime: number;
  isPlaying: boolean;
  watchStatus: string;
  videoURL: string;
  thumbNailURL: string;
  videoType: string;
  currentStep: number;
}
interface IEventReviewState {

  videoEvent: IAjaxState<any>;
  hasError: boolean;
  videoInformation: VideoState[];
  currentVideoInformation: VideoState;
  videoCount: number;
  baseUrl: string;
  acceptDriverEvent: IAjaxState<any>;
  sendDriverDiscussionResult: IAjaxState<any>;
  videoEventHistoryAddResult: IAjaxState<any>;
  token: string;
  redirectTo: string;
  playEnabled: boolean;
  eventDescription: string; 
  eventType: string;
  eventTime: string;
  driverName: string;
  vehicleId: string;
  address: string;
  cityRegion: string;
  score?: number;
  showSpeedLimit?: boolean;
  speedLimit?: string;
  videoEventTypePairings : VideoEventTypePairingResponse[];
  videoEventTypeString : string;
}

class EventReviewFreezerService extends FreezerService<IEventReviewState, typeof InjectedPropName> {
  constructor() {
    super({
      hasError: false,
      videoEvent: managedAjaxUtil.createInitialState(),
      videoInformation: [],
      currentVideoInformation: {} as VideoState,
      videoCount: 0,
      eventDescription: "N/A",
      eventType: "N/A",
      eventTime: "N/A",
      driverName: "N/A",
      vehicleId: "N/A",
      address:"",
      cityRegion:"",
      score:undefined,
      showSpeedLimit: false,
      speedLimit: "",
      baseUrl: "https://" + location.hostname,
      acceptDriverEvent: managedAjaxUtil.createInitialState(),
      sendDriverDiscussionResult: managedAjaxUtil.createInitialState(),
      videoEventHistoryAddResult: managedAjaxUtil.createInitialState(),
      token: "",
      redirectTo: "",
      playEnabled: true,
      videoEventTypePairings: [],
      videoEventTypeString : ""

    }, InjectedPropName);
  }

  public async getDriverEvent(token: string): Promise<any> {

    //set the token in the freezer
    this.freezer.get().set({ token });

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "videoEvent",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        if (params === undefined) {
          throw new Error("Parameters is undefined");
        }

        const identityApi = DriverEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return identityApi.apiV1VerifyemaildrivereventGet(params);
      },
      params: {
        token,
      },
      onOk: (data: any)  => {
        this.generateVideoInformation(data);
        if (data.error !== null) {
          this.freezer.get().set({ redirectTo: "/EventExpired" });
        }
        return data;
      },
      onError: () => {
        this.freezer.get().set({ hasError: true });
        this.freezer.get().set({ redirectTo: "/EventExpired" });
      }
    });
  }
  private setupEventData(data: any){
    let address, cityRegion;
    address = cityRegion = "";
    if (data.length > 0) {
      const eventData = data[0];
      if (eventData.addressStreetNumber) {
        address += eventData.addressStreetNumber;
        if (eventData.addressStreetName) {
            address += " ";
        }
      }

      if (eventData.addressStreetName) {
          address += eventData.addressStreetName;
      }

      if (eventData.addressCityName) {
          cityRegion += eventData.addressCityName;
          if (eventData.addressRegion) {
              cityRegion += ", ";
          }
      }

      if (eventData.addressRegion) {
          cityRegion += eventData.addressRegion;
      }

      this.freezer.get().set({ eventDescription: eventData.vehicleDescription });
      this.freezer.get().set({ eventType: eventData.videoEventTypeString });
      this.freezer.get().set({ eventType: eventData.videoEventTypeString });
      this.freezer.get().set({ eventTime: eventData.eventStartDate });
      this.freezer.get().set({ address: address});
      this.freezer.get().set({ cityRegion: cityRegion});
      this.freezer.get().set({ driverName: eventData.driverName});
      this.freezer.get().set({ vehicleId: eventData.vehicleId});
      this.freezer.get().set({ score: eventData.score});
      this.freezer.get().set({ videoEventTypePairings: eventData.videoEventTypePairings});
      this.freezer.get().set({ videoEventTypeString: eventData.videoEventTypeString});

      this.getEventSpeedLimit(eventData);
    }
  }

  private generateVideoInformation(videoInfo: any) {
    const data = videoInfo.data ? videoInfo.data : {};

    //Setup Event Data
    this.setupEventData(data);

    //Setup Video Tracking Information
    const assets: VideoAssetResponse[] = data && data.length > 0 ? data[0].videoAsset : [];    
    let newVideoInfo: any = [];  
    let count = 0;     

    const thumbnailVideo = assets.find((asset) => asset.type === 'Thumbnail');
    const primaryFileUploadType = thumbnailVideo?.fileUploadTypeId || 'FrontVideo';
    const defaultVideo = this.findAsset(assets, primaryFileUploadType, "Transcode") || this.findAsset(assets, primaryFileUploadType, "Full");
    
    if(defaultVideo){
      let primaryVideo = this.createVideoState(defaultVideo, count);

      if(defaultVideo.fileUploadId) {
        let primaryThumbAsset = this.findAsset(assets, primaryFileUploadType, "Thumbnail");
        if(primaryThumbAsset) {
          if(primaryThumbAsset.fileUploadId) {
            primaryVideo.thumbNailURL = MediaUrlBuilder.buildUrl(primaryThumbAsset, this.getBaseUrl());
          }
        }
      }

      //Set the current Video to the first one in the array.
      this.freezer.get().set({ currentVideoInformation: primaryVideo });

      count += 1;
      newVideoInfo.push(primaryVideo);
    }
    
    //Order Videos by List below.  This only will allow 4 channels.
    const fileUploadTypes = ["FrontVideo", "RearVideo", "SideVideo", "AltVideo"];
    
    // CREATE LIST OF VIDEOS WITH RELAVANT UI INFO 
    fileUploadTypes.forEach((fileUplaodType) => {
      if(primaryFileUploadType !== fileUplaodType) {         
        var video = assets.find(x=>x.type !== undefined && x.type === 'Transcode' && x.fileUploadTypeId === fileUplaodType);      
        if(video) {
          
          let currentVideo = this.createVideoState(video, count);

          if(video.fileUploadId) {
            currentVideo.videoId = video.fileUploadId;
            //Look to see if there is a thumbNail.
            const thumbNail = assets.find(
              (vid) =>
                  vid.fileUploadTypeId === fileUplaodType &&
                  vid.type === 'Thumbnail');
            if(thumbNail) {
                if(thumbNail.fileUploadId) {
                  currentVideo.thumbNailURL = MediaUrlBuilder.buildUrl(thumbNail, this.getBaseUrl());
                }
            }
          }         

          count += 1;
          newVideoInfo.push(currentVideo);
        }
      }
    })

    // Update freezer 
    this.freezer.get().set({ videoInformation: newVideoInfo }); 
    this.freezer.get().set({ videoCount: count });   
  }

  private createVideoState(video: VideoAssetResponse, count: number ) : VideoState
  {
    let currentVideo = {} as VideoState;
    currentVideo.videoURL = MediaUrlBuilder.buildUrl(video, this.getBaseUrl());
    currentVideo.videoType = video.fileUploadTypeDisplayName ? video.fileUploadTypeDisplayName : "";
    currentVideo.scrubTime = 0;
    currentVideo.watchStatus = 'Not Watched';
    currentVideo.isPlaying = false; 
    currentVideo.currentStep = count;
    return currentVideo;
  }
  private findAsset(assets: VideoAssetResponse[], fileUploadType: FileUploadTypeEnum, type: VideoAssetTypeEnum): VideoAssetResponse | undefined {
    return assets.find(
        (asset) =>
            typeof asset.fileUploadTypeId !== 'undefined' &&
            asset.type === type &&
            asset.fileUploadTypeId === fileUploadType,
    )
  }

  public async acceptDriverEvent(token: string): Promise<any> {

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "acceptDriverEvent",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        if (params === undefined) {
          throw new Error("Parameters is undefined");
        }

        const identityApi = DriverEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return identityApi.apiV1AcceptdrivereventGet(params);
      },
      params: {
        token,
      },
      onOk: (data: any) => {
        if (data.error !== null) {
          this.freezer.get().set({ redirectTo: "/DriverEventAccepted" });
        }
        return data;
      },
      onError: () => {
        this.freezer.get().set({ hasError: true });
      }
    });
  }

  public async sendDriverDiscussion(token: string, discussion: string): Promise<any> {

    const driverDiscussion: DriverDiscussionRequest = { token, discussion };
    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "sendDriverDiscussionResult",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        if (params === undefined) {
          throw new Error("Parameters is undefined");
        }

        const identityApi = DriverEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return identityApi.apiV1SenddriverdiscussionPost(params);
      },

      params: {
        body: driverDiscussion
      },
      onOk: (data: any) => {
        if (data.error !== null) {
          this.freezer.get().set({ redirectTo: "/DriverDiscussionSent" });

        }
        return data;
      },
      onError: () => {
        this.freezer.get().set({ hasError: true });
      }
    });
  }

  public async addVideoEventHistoryRecord(token: string, videoEventHistoryAction: VideoEventActionTypeEnum, camera: string): Promise<any> {

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "videoEventHistoryAddResult",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        if (params === undefined) {
          throw new Error("Parameters is undefined");
        }

        const identityApi = DriverEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return identityApi.apiV1AddvideoeventhistoryentryPost(params);
      },
      params: {
        token,
        videoEventHistoryAction,
        camera
      },
      onOk: (data: any) => {

        return data;
      },
      onError: () => {
        this.freezer.get().set({ hasError: true });
      }
    });
  }

  public getBaseUrl(): string {
    return this.freezer.get().baseUrl;
  }

  public setBaseUrl(url: string) {
    if (url !== "") {
      this.freezer.get().set({ baseUrl: url });
    }
  }

  public toggleVideoDetailsPlaying(videoId: number) {
    let videoInfo = _.cloneDeep(this.freezer.get().videoInformation);
    let newVideoInfo: any = [];
    videoInfo.forEach((video)=>{
      let currentVideo: VideoState = video;
      
      if(video.videoId === videoId) {
        currentVideo.isPlaying = !currentVideo.isPlaying;
        if(video.watchStatus === "Not Watched") {
          currentVideo.watchStatus = 'Opened';
        }
        // Set current video for easy access
        this.freezer.get().set({ currentVideoInformation: currentVideo });

        newVideoInfo.push(currentVideo);
      }
      else {
        currentVideo.isPlaying = false;
        newVideoInfo.push(currentVideo);
      }
    });

    this.freezer.get().set({ videoInformation: newVideoInfo });    
  }

  public setCurrentVideo(videoStep: number) {
    let videoInfo = _.cloneDeep(this.freezer.get().videoInformation);
    let newVideoInfo: any = [];
    videoInfo.forEach((video)=> {      
      let currentVideo: VideoState = video;
      
      if(currentVideo.currentStep === videoStep) {
        // Set current video for easy access
        this.freezer.get().set({ currentVideoInformation: currentVideo });
        this.freezer.get().set({ playEnabled: true });
        //return;
      }
      else{
        currentVideo.isPlaying = false;
      }
      newVideoInfo.push(currentVideo);
    });
    this.freezer.get().set({ videoInformation: newVideoInfo });  
  }
  public getCurrentVideo() : VideoState {

    return this.freezer.get().currentVideoInformation;
  }

  private addVideoWatchHistoryRecord(token: string, status: "Not Watched" | "Opened" | "Fully Watched", camera: string) {
    if (status === "Opened") {
      this.addVideoEventHistoryRecord(token, "DriverStartedWatchingVideo", camera);
    } else if (status === "Fully Watched") {
      this.addVideoEventHistoryRecord(token, "DriverCompletedWatchingVideo", camera);
    }
  }

  private getEventSpeedLimit(videoInfo: any) {
    const speedLimitEventId = 29;
    const criticalSpeedEventId = 34;

    if (videoInfo.videoEventType?.indexOf(speedLimitEventId) !== -1 || videoInfo.videoEventType?.indexOf(criticalSpeedEventId) !== -1) {
      if (videoInfo.eventJson) {
        let eventJson = JSON.parse(videoInfo.eventJson);
        try {

          // Speed Limit is currently stored only in the EventJson which is an arbitrary JSON that comes from the TelematicProvider
          let speedLimit = "";
          try
          {
            //Attempt to pull speed limit from Streamax json
            const eventSource = eventJson.filter((e: any) => e["TelematicRuleName"] == "56007" || e["TelematicRuleName"] == "CRITICALSPEEDING")[0]["Source"];
            speedLimit = JSON.parse(JSON.stringify(eventSource).toLocaleLowerCase())['alarmadditionalinfo']['speedlimit'];
          }
          catch
          {
            //Attempt to pull speed limit from MiTAC json
            speedLimit = eventJson.filter((e: any) => e["TelematicRuleName"] == "3005" || e["TelematicRuleName"] == "CRITICALSPEEDING")[0]["Source"]["detectedSpeedLimit"];
          }

          this.freezer.get().set({ showSpeedLimit: true});
          this.freezer.get().set({ speedLimit: `${speedLimit} MPH`});
        }
        catch {
          this.freezer.get().set({ showSpeedLimit: false});  
        }
      }
    } else {
      this.freezer.get().set({ showSpeedLimit: false});  
    }
  }

  public setVideoStatus(status: "Not Watched" | "Opened" | "Fully Watched", videoId: number) {
    const freezer = this.freezer.get();
    let videoInfo = _.cloneDeep(this.freezer.get().videoInformation);
    let newVideoInfo: any = [];
    videoInfo.forEach((video)=>{
      let currentVideo: VideoState = video;      
      if (video.videoId === videoId) {
        currentVideo.watchStatus = status;
        if (video.watchStatus !== "Fully Watched") {
          this.addVideoWatchHistoryRecord(freezer.token, status, video.videoType);
        }
      }
      newVideoInfo.push(currentVideo);
    })
    this.freezer.get().set({ videoInformation: newVideoInfo }); 
  }

  public setVideoStatusToFullyWatched(videoId: number) {
    const freezer = this.freezer.get();
    let videoInfo = _.cloneDeep(this.freezer.get().videoInformation);
    let newVideoInfo: any = [];
    videoInfo.forEach((video)=>{
      let currentVideo: VideoState = video;      
      if (video.videoId === videoId) {
        currentVideo.watchStatus = "Fully Watched";
        this.addVideoWatchHistoryRecord(freezer.token, "Fully Watched", video.videoType);
      }
      newVideoInfo.push(currentVideo);
    })
    this.freezer.get().set({ videoInformation: newVideoInfo }); 
  }

  public resetRedirectTo() {
    this.freezer.get().set({ redirectTo: "" });
  }

  public togglePlayEnabled(value: boolean) {
    this.freezer.get().set({ playEnabled: value });
  }
}

export const EventReviewService = new EventReviewFreezerService();
export type IEventReviewServiceInjectedProps = ReturnType<EventReviewFreezerService["getPropsForInjection"]>;
