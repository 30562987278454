import { React } from "../Imports";
import { Card, Typography, CardContent, Container } from "../MaterialUIComponents";
import { IEventReviewServiceInjectedProps, EventReviewService } from "../state/EventReviewFreezer";
import { ConfigService, IConfigServiceInjectedProps } from "$State/ConfigFreezerService";
import { GetImageUrl } from "$Utilities/dataModelUtilities";
interface IDriverEventAcceptedBaseProps {
}

type IDriverEventAcceptedProps = IDriverEventAcceptedBaseProps & IEventReviewServiceInjectedProps & IConfigServiceInjectedProps;

interface IDriverEventAcceptedState {
    errorMsg: string;
    id: string;
    token: string;
    passwordHasError: boolean;

}

const styles = require("./DriverEventAccepted.scss") as {
    main: string,
    loginbutton: string,
    content: string,
    centeredText: string;
    buttonContainer: string;
    flex: string;
    bottomInfo: string;
    topInfo: string;
    logo: string;
};

class _DriverEventAccepted extends React.Component<IDriverEventAcceptedProps, IDriverEventAcceptedState> {
    state: IDriverEventAcceptedState = {
        errorMsg: "",
        id: "",
        token: "",
        passwordHasError: false
    };

    componentDidMount() {
        this.props.eventReview.resetRedirectTo();
    }

    render() {
        let currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        let currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;
       
        return (
            <Container className={styles.main}>
                <Card className={styles.content} >
                <img className={styles.logo} src={GetImageUrl(currentImagesBucket? currentImagesBucket : '', currentRegion? currentRegion : '', 'videoProtectsStacked.png')} />
                    <CardContent className={styles.centeredText}>
                        <Typography variant="h5" component="h2" align="center" className={styles.centeredText}>
                            Thank you for accepting the video event. Your acceptance has been communicated to the fleet manager.
                        </Typography>
                    </CardContent>
                </Card>
                
            </Container>
        );
    }
}

export const DriverEventAcceptedPage = EventReviewService.inject(ConfigService.inject(_DriverEventAccepted));